import styles from "./page.module.scss";

function SchedulePlitka(props: any) {
  function DateFormatter({ date }: { date: string }) {
    const formattedDate = new Date(date)
      .toLocaleString("ru-RU", {
        weekday: "long",
        day: "numeric",
        month: "long",
        year: "numeric",
      })
      .replace(",", " -")
      .replace(" г.", "");

    return formattedDate;
  }

  return (
    <div className={styles.SchedulePlitka}>
      <div className={styles.title}>
        <p>{DateFormatter({ date: props.date })}</p>
      </div>
      <div className={styles.box}>
        {props.item.map((el: any, index: number) => (
          <div key={index} className={styles.boxInner}>
            {el.time && (
              <span>{el.time.split(":")[0] + ":" + el.time.split(":")[1]}</span>
            )}
            <p>{el.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SchedulePlitka;
