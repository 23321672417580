import { useState } from "react";
import styles from "./page.module.scss";
import HomePageLayout from "../../../Layout/HomePageLayout/layout";
import Payment from "../../Payment/Payment";

function HomeModuleTitle() {
  const [showPayment, setShowPayment] = useState(false);
  return (
    <>
      <div style={{ display: showPayment ? "block" : "none" }}>
        <Payment showPayment={showPayment} setShowPayment={setShowPayment} />
      </div>
      <div className={styles.HomeModuleTitle}>
        <HomePageLayout>
          <div className={styles.containerMain}>
            <div className={styles.Left}>
              <img src="/img/hram.svg" alt="img" />
            </div>
            <div className={styles.Right}>
              <div className={styles.title}>
                <img src="/img/fly.svg" alt="img" />
                <img src="/img/fly.svg" alt="img" />
                <h2>Храм Пророка Илии</h2>
              </div>
              <div className={styles.btn}>
                <img
                  className={styles.imgleft}
                  src="/img/lepestok.svg"
                  alt="img"
                />
                <button onClick={() => setShowPayment(true)}>
                  Хочу помочь построить храм
                </button>
                <img
                  className={styles.imgtop}
                  src="/img/lepestok.svg"
                  alt="img"
                />
                <img
                  className={styles.imgbottom}
                  src="/img/lepestok.svg"
                  alt="img"
                />
                <img
                  className={styles.imgUzor2}
                  src="/img/uzor2.svg"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </HomePageLayout>
      </div>
    </>
  );
}

export default HomeModuleTitle;
