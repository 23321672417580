import "./../../styles/globals.css";
import SchedulePage from "../../page/SchedulePage/page";
import Header from "../../components/Header/page";
import Footer from "../../components/Footer/page";
import { useEffect } from "react";

function Schedule() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <SchedulePage />
      <Footer />
    </div>
  );
}

export default Schedule;
