import StavrosSlider from "../../components/StavrosSlider/page";
import styles from "./page.module.scss";

function StavrosPage() {
  const images = [
    "/img/slide1.png",
    "/img/slide2.png",
    "/img/slide3.png",
    "/img/slide1.png",
    "/img/slide2.png",
    "/img/slide3.png",
  ];
  return (
    <div className={styles.StavrosPage}>
      <h2>
        Спортивный патриотический клуб
        <br /> “СТАВРОС” храма
      </h2>
      <div className={styles.box}>
        <div className={styles.left}>
          <img src="./img/bogatir.png" alt="img" />
        </div>
        <div className={styles.rigth}>
          <p>
            <span>B</span>оенно - патриотический спортивный клуб <br />{" "}
            "Ставрос" ведёт спортивное и патриотическое
            <br /> воспитание детей.
            <img className={styles.lepest2} src="/img/lep1.svg" alt="img" />
            <img
              className={styles.lepest}
              src="./img/lepestGrup.svg"
              alt="img"
            />
          </p>
          <p>
            Ежедневно в течение года проходят тренировки по <br /> джиу-джитсу,
            боксу, историческому фехтованию,
            <br /> скалолазанию.
          </p>
          <p>Также проводятся летние спортивные лагеря.</p>
        </div>
      </div>
      <div className={styles.slider}>
        <StavrosSlider images={images} />
        {/* <div className={styles.sliderPoints}>
          <div className={styles.point}></div>
          <div className={styles.point}></div>
          <div className={styles.point}></div>
        </div> */}
      </div>
      <div className={styles.imgasPlanshet}>
        {Array.from(new Set(images)).map((image, index) => (
          <img key={index} src={image} alt="img" />
        ))}
      </div>
      <div className={styles.boxInfo}>
        <div className={styles.info}>
          <h3>Иерей Павел Дудоладов</h3>
          <p>
            Духовник клуба,
            <br />
            настоятель храма пророка Илии
          </p>
          <p className={styles.phone}>+7 908 192 50 09</p>
        </div>
        <div className={styles.info}>
          <h3>Овчаренко Олег Игоревич </h3>
          <p className={styles.club}>Руководитель клуба</p>
          <p className={styles.phone}>+7 918 513 28 77</p>
        </div>
        <div className={styles.infoEnd}>
          <a href="https://vk.com/clubstavros">
            <img src="./img/vk.svg" alt="img" />
            <p>Группа “СТАВРОС”</p>
          </a>
        </div>
      </div>
      <img className={styles.uzor} src="/img/acuzor.svg" alt="img" />
    </div>
  );
}

export default StavrosPage;
