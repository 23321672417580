export interface Name {
  id: string;
  value: string;
}

export const pardonData = [
  {
    name: "Литургия",
    types: ["О здравии", "О упокоении"],
    price: "Записка (1 имя) - 150 рублей.",
  },
  {
    name: "Молебен",
    types: ["О здравии"],
    price: "Записка (1 имя) - 20 рублей.",
  },
  {
    name: "Панихида",
    types: ["О упокоении"],
    price: "Записка (1 имя) - 20 рублей.",
  },
  {
    name: "Сорокоуст",
    types: ["О здравии", "О упокоении"],
    price: "Одно имя - 500 рублей.",
  },
  {
    name: "Поставить свечу",
    types: ["О здравии", "О упокоении"],
    price: "Одно имя - 50 рублей.",
  },
  {
    name: "Именной кирпичик",
    types: ["Вечное поминовение", "На один год"],
    price:
      "Одно имя (на вечное поминовение) - 5 000 рублей. Одно имя (на 1 год) - 1 000 рублей.",
  },
];

export const DonatType = [
  "Литургия",
  "Молебен",
  "Панихида",
  "Сорокоуст",
  "Поставить свечу",
  "Именной кирпичик",
];
export const TitleTipe = [
  "О здравии",
  "О упокоении",
  "Вечное поминовение",
  "На один год",
];
