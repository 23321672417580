import { serverApi } from "../../api/ApiRequest";
import styles from "./page.module.scss";

function RightsPage() {
  const server = serverApi;
  return (
    <div className={styles.RightsPage}>
      <h1>Правовая информация</h1>
      <div className={styles.container}>
        <div
          className={styles.box}
          onClick={() => window.open(`${server}/uploads/ustav.pdf`)}
        >
          <img src="/img/document.svg" alt="img" />
          <p>Устав</p>
        </div>

        <div
          className={styles.box}
          onClick={() => window.open(`${server}/uploads/oferta.pdf`)}
        >
          <img src="/img/document.svg" alt="img" />
          <p>
            Оферта о добровольном <br /> пожертвовании
          </p>
        </div>

        <div
          className={styles.box}
          onClick={() => window.open(`${server}/uploads/smu.jpg`)}
        >
          <img src="/img/document.svg" alt="img" />
          <p>
            Свидетельство <br /> Министерства Юстиции
          </p>
        </div>
        <div
          className={styles.box}
          onClick={() => window.open(`${server}/uploads/inn.jpg`)}
        >
          <img src="/img/document.svg" alt="img" />
          <p>Свидетельство ИНН</p>
        </div>
      </div>
    </div>
  );
}

export default RightsPage;
