import styles from "./page.module.scss";
function HomeModuleAdress() {
  return (
    <div className={styles.HomeModuleAdress}>
      <div className={styles.HomeModuleAdress_inner}>
        <h2>Наши контакты</h2>
        <div className={styles.containerFlex}>
          <div className={styles.left}>
            <h3>Храм пророка Илии</h3>
            <p>
              347942, г. Таганрог, микрорайон <br />
              Андреевский, улица Победы, 112
            </p>
            <p
              onClick={() => {
                window.open("mailto:paveldudoladov@mail.ru");
              }}
              className={styles.email}
            >
              paveldudoladov@mail.ru
            </p>
          </div>
          <div className={styles.right}>
            <iframe
              src="https://yandex.ru/map-widget/v1/?um=constructor%3A61d6be5c5f1e788bc606ff245ea7cee584ba910294a95d982906d4d6ac406348&amp;source=constructor"
              width="100%"
              height="100%"
              frameBorder="0"
            ></iframe>
          </div>
        </div>
        <img className={styles.uzor3} src="/img/uzor3.svg" alt="img" />
      </div>
    </div>
  );
}

export default HomeModuleAdress;
