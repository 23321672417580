import styles from "./page.module.scss";
import { setId } from "./../../store/Ids/Ids"; // Ensure this is the correct import
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

interface NewsCardProps {
  dataNews: {
    id: string;
    imgUrls: string[];
    title: string;
    text: string;
    date: string;
  };
}

function NewsCard({ dataNews }: NewsCardProps) {
  const [pointsShow, setPointsShow] = useState(false);

  const dispatch = useDispatch();
  // const store = useSelector((state: any) => state.idSlice);

  const ClickEcho = (id: string) => {
    console.log("id", id);
    dispatch(setId({ id }));
  };

  // const pathname = useLocation();

  // useEffect(() => {
  //   const hash = window.location.hash;
  //   if (hash) {
  //     const element = document.getElementById(hash.substring(1));
  //     if (element) {
  //       const elementPosition =
  //         element.getBoundingClientRect().top + window.scrollY;
  //       const offsetPosition = elementPosition - 200;
  //       window.scrollTo({
  //         top: offsetPosition,
  //         behavior: "smooth",
  //       });
  //     }
  //   }
  // }, [pathname]);

  useEffect(() => {
    // Проверяем, есть ли якорь в URL на момент загрузки компонента
    const hash = window.location.hash;
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        // Прокручиваем к элементу
        element.scrollIntoView();
      }
      // Удаляем якорь из URL после прокрутки к элементу
      window.history.replaceState(null, "", window.location.pathname);
    }
  }, []);

  const FormattedText = (text: string) => {
    let trimmedText = "";
    let lastSpaceIndex = text.length;
    if (text.length > 750) {
      trimmedText = text.slice(0, 600);
      lastSpaceIndex = trimmedText.lastIndexOf(" ");
    } else {
      trimmedText = text;
    }
    const formattedText =
      lastSpaceIndex > -1 ? trimmedText.slice(0, lastSpaceIndex) : trimmedText;
    const updatedText = formattedText
      .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">ссылка</a>')
      .replace(
        /\[club(\d+)\|([^\]]+)\]/g,
        '<a href="https://vk.com/club$1">$2</a>'
      )
      .replace(
        /\[id(\d+)\|([^\]]+)\]/g,
        '<a href="https://vk.com/id$1">$2</a>'
      );

    return updatedText;
  };

  useEffect(() => {
    if (dataNews.text.length > 750) {
      setPointsShow(true);
    } else {
      setPointsShow(false);
    }
  }, [dataNews.text]);
  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  console.log("dataNews", dataNews);
  return (
    <div className={styles.NewsCard} key={dataNews?.id} id={dataNews?.id}>
      <div className={styles.imgNews}>
        {dataNews?.imgUrls[0] ? (
          <div className={styles.EsimgNews}>
            <img
              src={dataNews.imgUrls[0]}
              alt="News"
              onError={(e) => {
                e.currentTarget.src = "/img/NotPhotoNews.svg";
              }}
            />
          </div>
        ) : (
          <div className={styles.NotimgNews}>
            <img src="/img/NotPhotoNews.svg" alt="No Photo Available" />
          </div>
        )}
      </div>
      <div className={styles.CardInfoBlock}>
        <div className={styles.NewsCardInfo}>
          <div className={styles.NewsCardFirst}>
            <p
              dangerouslySetInnerHTML={{
                __html: FormattedText(dataNews?.text),
              }}
            ></p>
          </div>
          {pointsShow && <p className={styles.points}>...</p>}
        </div>
        <p className={styles.dateMobile}>
          {formatDate(new Date(dataNews?.date))}
        </p>
        <div className={styles.imgNewsPhone}>
          {dataNews?.imgUrls[0] ? (
            <div className={styles.EsimgNews}>
              {/* <div
                className={styles.backgroundImage}
                style={{ backgroundImage: `url(${dataNews.imgUrls[0]})` }}
              /> */}
              <img
                src={dataNews.imgUrls[0]}
                alt="News"
                className={styles.foregroundImage}
                onError={(e) => {
                  e.currentTarget.src = "/img/NotPhotoNews.svg";
                }}
              />
            </div>
          ) : (
            <div className={styles.NotimgNews}>
              <img src="/img/NotPhotoNews.svg" alt="No Photo Available" />
            </div>
          )}
        </div>

        <div className={styles.NewsCardSecond}>
          <div className={styles.NewsCardSecondInner}>
            <p className={styles.dateDesctope}>
              {formatDate(new Date(dataNews?.date))}
            </p>
            <Link to="/newsMore">
              <button onClick={() => ClickEcho(dataNews?.id)}>
                Подробнее...
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
