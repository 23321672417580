import { useEffect } from "react";
import Footer from "../../components/Footer/page";
import Header from "../../components/Header/page";
import StavrosPage from "../../page/StavrosPage/page";
import "./../../styles/globals.css";

function Stavros() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <StavrosPage />
      <Footer />
    </div>
  );
}

export default Stavros;
