import { useEffect, useState } from "react";
import styles from "./page.module.scss";
import moment from "moment";
import Column from "./Column";

function MiniCalendarComponent(props: any) {
  const days = ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"];
  const daysIndex = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  //   const currentDate = moment();
  // const [step, setStep] = useState(0);
  const step = 0;
  const [currentDate, setCurrentDate] = useState(
    moment(props.selectedDateCalendar).add(step, "days")
  );
  //   const currentDate = moment().add(0, "days");
  const [selecedDate, setSelectedDate] = useState<number>(currentDate.day());

  // const [dataDay, setDataDay] = useState<any[]>([
  //   currentDate.day() - 3,
  //   currentDate.day() - 2,
  //   currentDate.day() - 1,
  //   currentDate.day(),
  //   currentDate.day() + 1,
  //   currentDate.day() + 2,
  //   currentDate.day() + 3,
  // ]);

  const [dataDay, setDataDay] = useState<any[]>([]);
  // Генерация массива с понедельника по воскресенье
  const generateDataDay = (date: moment.Moment) => {
    const currentDay = date.day(); // День недели (0 - воскресенье, 1 - понедельник, ..., 6 - суббота)
    const startOfWeek =
      currentDay === 0
        ? date.clone().subtract(6, "days") // Если воскресенье, сдвигаем назад на 6 дней (до понедельника)
        : date.clone().startOf("week").add(1, "days"); // Иначе обычное начало недели с понедельника

    return Array.from({ length: 7 }, (_, i) =>
      startOfWeek.clone().add(i, "days")
    ); // Создаем массив дат (с понедельника по воскресенье)
  };

  useEffect(() => {
    const updatedDate = moment(props.selectedDateCalendar);
    setCurrentDate(updatedDate);
    setDataDay(generateDataDay(updatedDate));
  }, [props.selectedDateCalendar]);

  useEffect(() => {
    const m = moment(props.selectedDateCalendar).add(step, "days");
    setCurrentDate(m);
    setSelectedDate(m.day());
    // funSetDataDay(m);
  }, [props.selectedDateCalendar]);

  // const funSetDataDay = (cd: any) => {
  //   setDataDay([
  //     cd.day() - 3,
  //     cd.day() - 2,
  //     cd.day() - 1,
  //     cd.day(),
  //     cd.day() + 1,
  //     cd.day() + 2,
  //     cd.day() + 3,
  //   ]);
  // };

  //! при клике на дату
  const funSetSelectedDate = (index: number) => {
    const cd = moment(props.selectedDateCalendar).add(
      index - selecedDate,
      "days"
    );
    // funSetDataDay(cd);
    props.setSelectedDateCalendar(index);
  };

  // const funPlusStep = () => {
  //   const cd = moment(props.selectedDateCalendar).add(1, "days");
  //   funSetDataDay(cd);
  //   props.setSelectedDateCalendar(cd?.toDate());

  //   // Получаем новый месяц и год из новой даты
  //   const newMonth = cd.month(); // Месяц - 0 (январь) до 11 (декабрь)
  //   const newYear = cd.year(); // Год
  //   // Обновляем выбранную дату
  //   props.setSelectedDateCalendar(cd?.toDate());
  //   // Обновляем activeStartDate новыми значениями года и месяца
  //   const updatedActiveStartDate = new Date(props.activeStartDate);
  //   updatedActiveStartDate.setFullYear(newYear);
  //   updatedActiveStartDate.setMonth(newMonth);
  //   props.setActiveStartDate(updatedActiveStartDate);
  // };

  // const funMinusStep = () => {
  //   const cd = moment(props.selectedDateCalendar).add(-1, "days");
  //   funSetDataDay(cd);
  //   props.setSelectedDateCalendar(cd?.toDate());

  //   // Получаем новый месяц и год из новой даты
  //   const newMonth = cd.month(); // Месяц - 0 (январь) до 11 (декабрь)
  //   const newYear = cd.year(); // Год
  //   // Обновляем выбранную дату
  //   props.setSelectedDateCalendar(cd?.toDate());
  //   // Обновляем activeStartDate новыми значениями года и месяца
  //   const updatedActiveStartDate = new Date(props.activeStartDate);
  //   updatedActiveStartDate.setFullYear(newYear);
  //   updatedActiveStartDate.setMonth(newMonth);
  //   props.setActiveStartDate(updatedActiveStartDate);
  // };

  const getWeekday = (dateString: any) => {
    const date = new Date(dateString);
    const dayIndex = date.getDay(); // Получаем день недели от 0 (воскресенье) до 6 (суббота)
    return daysIndex[dayIndex]; // Возвращаем название дня недели
  };

  return (
    <div className={styles.MiniCalendarComponent}>
      <div className={styles.arrow}>
        <img
          onClick={props.funMinusStep}
          style={{ transform: "rotate(-90deg)" }}
          src="/img/arrow.svg"
          alt="img"
        />
      </div>

      {dataDay.map((dayMoment, index) => (
        <Column
          key={index}
          index={daysIndex.findIndex(
            (el) => el === getWeekday(dayMoment.toDate())
          )}
          day={days[index]}
          currentDate={dayMoment}
          selecedDate={selecedDate}
          funSetSelectedDate={() => funSetSelectedDate(dayMoment.toDate())}
        />
      ))}
      <div className={styles.arrow}>
        <img
          onClick={props.funPlusStep}
          style={{ transform: "rotate(90deg)" }}
          src="/img/arrow.svg"
          alt="img"
        />
      </div>
    </div>
  );
}

export default MiniCalendarComponent;
