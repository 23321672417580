import { Link } from "react-router-dom";
import styles from "./page.module.scss";

function AboutComingPage() {
  return (
    <div className={styles.AboutComingPage}>
      <div className={styles.container}>
        <h2>Приход храма</h2>
        <div className={styles.box}>
          <div className={styles.plitka}>
            <img src="/img/ac1.svg" alt="img" />
            <div className={styles.Bottom}>
              <p>Спортивный патриотический клуб “СТАВРОС”</p>
              <Link to="/stavros">
                <button>Подробнее...</button>
              </Link>
            </div>
          </div>
          <div className={styles.plitka} style={{ marginRight: "0px" }}>
            <img src="/img/ac2.svg" alt="img" />
            <div className={styles.Bottom}>
              <p>Духовенство</p>
              <Link to="/souls">
                <button>Подробнее...</button>
              </Link>
            </div>
          </div>
          {/* <div className={styles.plitka}>
            <img src="/img/ac3.svg" alt="img" />
            <div className={styles.Bottom}>
              <p>Воскресная школа</p>
              <Link href="/SundaySchool">
                <button>Подробнее...</button>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
      <img className={styles.uzor} src="/img/acuzor.svg" alt="img" />
    </div>
  );
}

export default AboutComingPage;
