"use client";
import "./styles/globals.css";
import Home from "./app/home/page";
import { Route, Routes } from "react-router-dom";
import AboutComing from "./app/aboutComing/page";
import News from "./app/news/page";
import Schedule from "./app/schedule/page";
import TempleCalendar from "./app/templeCalendar/page";
import Pardon from "./app/pardon/page";
import Rights from "./app/rights/page";
import Stavros from "./app/stavros/page";
import Souls from "./app/souls/page";
import NewsMore from "./app/newsMore/page";

export default function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutComing" element={<AboutComing />} />
        <Route path="/news" element={<News />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/templeCalendar" element={<TempleCalendar />} />
        <Route path="/pardon" element={<Pardon />} />
        <Route path="/rights" element={<Rights />} />
        <Route path="/stavros" element={<Stavros />} />
        <Route path="/souls" element={<Souls />} />
        <Route path="/newsMore" element={<NewsMore />} />
      </Routes>
    </div>
  );
}
