import "./../../styles/globals.css";
import NewsPage from "../../page/News/page";
import Header from "../../components/Header/page";
import Footer from "../../components/Footer/page";
import { useEffect } from "react";

function News() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <NewsPage />
      <Footer />
    </div>
  );
}

export default News;
