import { useEffect } from "react";
import HomeModuleAdress from "../../module/HomePage/HomeModuleAdress/page";
import HomeModuleContacts from "../../module/HomePage/HomeModuleContacts/page";
import HomeModuleInfo from "../../module/HomePage/HomeModuleInfo/page";
import HomeModuleTitle from "../../module/HomePage/HomeModuleTitle/page";
import HomePageConstruction from "../../module/HomePage/HomePageConstruction/page";
import styles from "./page.module.scss";

function HomePage() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://widgets.donation.ru/wloader/fb20bd9c-f8d5-4d1d-9266-fc4852c47148/wloader.js";
    script.async = true;
    script.onload = () => {
      // Здесь можете добавить код, который нужно выполнить после загрузки скрипта
      console.log("Script loaded successfully");
    };
    script.onerror = () => {
      console.error("Error loading the script");
    };

    document.body.appendChild(script);

    // Очистка при размонтировании компонента
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className={styles.HomePage}>
      <HomeModuleTitle />
      <HomeModuleInfo />
      <HomePageConstruction />
      <HomeModuleContacts />
      <HomeModuleAdress />
    </div>
  );
}

export default HomePage;
