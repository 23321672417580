import { useState } from "react";
import styles from "./page.module.scss";
import Payment from "../../Payment/Payment";

function HomePageConstruction() {
  const [showPayment, setShowPayment] = useState(false);

  return (
    <>
      <script
        src="https://widgets.donation.ru/wloader/fb20bd9c-f8d5-4d1d-9266-fc4852c47148/wloader.js"
        async
      ></script>
      <div style={{ display: showPayment ? "block" : "none" }}>
        <Payment showPayment={showPayment} setShowPayment={setShowPayment} />
      </div>
      <div className={styles.HomePageConstruction}>
        <div className={styles.container}>
          <h2>Процесс строительства</h2>
          <div className={styles.box}>
            <div className={styles.left}>
              <p>
                Сейчас ведётся строительство основного <br /> каменного храма.
              </p>
              <p>
                При храме работает военно -<br /> патриотический спортивный{" "}
                <br />
                клуб "Ставрос".
              </p>
              <button onClick={() => setShowPayment(true)}>Хочу помочь</button>
              <img className={styles.uzor} src="/img/uhp.svg" alt="img" />
            </div>
            <div className={styles.rigth}>
              <img
                src="/img/hp3n.png"
                // srcSet="/img/hp3m.png 928w, /img/hp3p.png 998w, /img/hp3.png 4000w"
                alt="img"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePageConstruction;
