import styles from "./page.module.scss";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "./styles.css";

function StavrosSlider(props: any) {
  return (
    <div className={styles.StavrosSlider}>
      <Swiper
        modules={[Navigation, Pagination, Autoplay]}
        spaceBetween={50}
        slidesPerView={2}
        initialSlide={1}
        centeredSlides={true}
        loop={true}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
        navigation={{
          prevEl: `.${styles.prevButton}`,
          nextEl: `.${styles.nextButton}`,
        }}
        speed={800}
      >
        {props.images?.map((item: string, index: number) => (
          <SwiperSlide key={index}>
            <img
              src={item}
              alt={`Slide ${index + 1}`}
              className={styles.slide}
              onError={(e) => {
                // Устанавливаем альтернативное изображение при ошибке загрузки
                e.currentTarget.src = "/img/NotPhotoNews.svg";
              }}
            />
          </SwiperSlide>
        ))}
        <div className={styles.customNavigation}>
          <img
            className={styles.prevButton}
            src="./img/arrowSlider.svg"
            alt="img"
          />
          <img
            style={{ transform: "scaleX(-1)" }}
            className={styles.nextButton}
            src="./img/arrowSlider.svg"
            alt="img"
          />
        </div>
        <div className="swiper-pagination" />
      </Swiper>
    </div>
  );
}

export default StavrosSlider;
