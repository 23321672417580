import axios from "axios";
let url = "https://temple.dev.rdcenter.ru/api";
let server = "https://temple.dev.rdcenter.ru/api";

// if (typeof window !== "undefined") {
//   url = new URL(window.location.href);
//   server = `${url.origin}/api`;
// }
// const server = "http://localhost:3000";

const serv = process.env.NEXT_PUBLIC_SERVER;
console.log("serv", serv);
if (serv) {
  server = serv;
} else {
  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
    server = `${url.origin}/api`;
  }
}
if (server.startsWith("http://localhost")) server = "http://localhost:3000";
export const serverApi = server;
console.log("serverApi", serverApi);
const http = axios.create({
  withCredentials: true,
});

export const GetNews = async () => {
  try {
    const response = await http.get(`${server}/news`);
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const apiGetNewsId = async (id) => {
  try {
    const response = await http.get(`${server}/news/${id}`);
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

export const apiGetSchedule = async (date) => {
  try {
    const response = await http.get(`${server}/schedule?date=${date}`);
    return response;
  } catch (error) {
    console.error("Error:", error);
  }
};

//! расчет доната
export const apiDonation = async (data) => {
  try {
    const response = await http.post(`${server}/donation`, data);
    return response;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};

//! получить расписание для церковного календаря
export const apiGetHolidays = async (date) => {
  try {
    const response = await http.get(`${server}/holidays?date=${date}`);
    return response;
  } catch (error) {
    console.error("Error:", error);
    return error;
  }
};
