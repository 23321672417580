import PardonModuleForm from "../../module/Pardon/page";
import { Name, pardonData } from "./data";
import styles from "./page.module.scss";
import { useEffect, useState } from "react";
declare global {
  interface Window {
    Mixplat: any;
  }
}
function PardonPage() {
  const [selectedItem, setSeceltedItem] = useState<string>("Литургия");
  const [selectedType, setSeceltedType] = useState<string>("");

  const [nameLangth, setNameLangth] = useState<Name[]>([
    { id: "1", value: "" },
  ]);

  const funSetItem = (name: string) => {
    setSeceltedItem(name);
    setSeceltedType("");
    setNameLangth([
      {
        id: "1",
        value: "",
      },
    ]);
  };

  useEffect(() => {
    const se =
      pardonData.find((el) => el.name === selectedItem)?.types[0] || "";
    setSeceltedType(se);
  }, [selectedItem]);

  return (
    <div className={styles.PardonPage}>
      <div className={styles.container}>
        <h2>Подать поминовение</h2>

        <div className={styles.box}>
          <img className={styles.pardonImg} src="/img/PardonImg.svg" />
          <div className={styles.listPhone}>
            {pardonData.map((el, index) => (
              <div key={index + "n"} className={styles.listPhoneItem}>
                <p onClick={() => funSetItem(el.name)}>
                  <span
                    className={selectedItem === el.name ? styles.pActive : ""}
                  >
                    {" "}
                    {el.name}
                  </span>
                </p>
              </div>
            ))}
          </div>
          <div>
            <PardonModuleForm
              item={pardonData.find((el) => el.name === selectedItem)}
              selectedType={selectedType}
              selectedItem={selectedItem}
              setSeceltedType={setSeceltedType}
              nameLangth={nameLangth}
              setNameLangth={setNameLangth}
            />
          </div>
          <div className={styles.list}>
            {pardonData.map((el, index) => (
              <p onClick={() => funSetItem(el.name)} key={index + "p"}>
                <span
                  className={selectedItem === el.name ? styles.pActive : ""}
                >
                  {" "}
                  {el.name}
                </span>
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PardonPage;
