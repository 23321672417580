"use client";

import Footer from "../../components/Footer/page";
import Header from "../../components/Header/page";
import HomePage from "../../page/HomePage/page";
import "./../../styles/globals.css";

function Home() {
  return (
    <div>
      <Header />
      <HomePage />
      <Footer />
    </div>
  );
}

export default Home;
