import styles from "./page.module.scss";
function SoulsModule() {
  return (
    <main className={styles.SoulsModule}>
      <div className={styles.SoulsModuleContainer}>
        <div className={styles.SoulsModuleInner}>
          <h3>Духовенство</h3>
        </div>
        <div className={styles.SoulsModuleInnerTextContainer}>
          <div className={styles.SoulsModuleInnerText}>
            <div className={styles.imgBoxContainer}>
              <div className={styles.imgBox}>
                <img src="/img/Ilii.svg" />
                <img src="/img/caracumSouls.svg" className={styles.caracum} />
              </div>
            </div>
            <div className={styles.imgBoxContainerPhone}>
              <div className={styles.imgBoxPhone}>
                <div className={styles.imgBoxPhonePicture}>
                  <img src="/img/Ilii.svg" className={styles.imgFirst} />
                  <img
                    src="/img/caracumSouls.svg"
                    className={styles.caracumPhone}
                  />
                </div>
                <div className={styles.titlePhone}>
                  <p className={styles.titlePhoneText}>иерей Павел Дудоладов</p>
                  <p className={styles.SubtitlePhone}>
                    настоятель Ильинского храма
                  </p>
                  <div className={styles.DatePhone}>
                    <p className={styles.titlePhoneText}>Дата рождения:</p>
                    <p className={styles.SubtitlePhone}>24 января 1984 года</p>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.text}>
              <p className={styles.title}>
                Настоятель Ильинского храма иерей Павел Дудоладов
              </p>
              <p className={styles.date}>
                <strong>Дата рождения:</strong>
              </p>
              <p className={styles.date}>24 января 1984 года</p>
              <p>
                <strong>День тезоименитства:</strong>
              </p>
              <p>15 января</p>
              <p>
                26.12.2010 года архиепископом Ростовским и Новочеркасским
                Пантелеимоном (Долгановым) рукоположен в сан диакона, в апреле
                2015 г. рукоположён в сан иерея.
              </p>
              <p>
                <strong>Светское образование:</strong>
              </p>
              <p>
                Таганрогский механический колледж. Специальность: Техник
                технолог сварочного производства. Донской государственный
                технический университет 2006г. Специальность: Инженер технологии
                и оборудования сварочного производства.
              </p>
              <p>В 2014 году окончил Донскую Духовную Семинарию.</p>
              <p>
                <strong>Награды:</strong>
              </p>
              <p>
                Набедренник 2016г.<br></br> 15 августа 2023 года назначен
                настоятелем Ильинского храма г.Таганрога.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.CorespText}>
          <p className={styles.CorespTextTitle}>
            <strong>Адрес для корреспонденции:</strong>
          </p>
          <p>
            <a href="mailto:paveldudoladov@mail.ru">paveldudoladov@mail.ru</a>
          </p>
          <p>
            <a href="tel:+79081925009">+7 908 192 50 09</a>
          </p>
        </div>
      </div>
    </main>
  );
}

export default SoulsModule;
