import { createSlice } from "@reduxjs/toolkit";

const idSlice = createSlice({
  name: "idSlice",
  initialState: {
    newsData: [],
    id: "",
    filteredDate: [],
    lengthMass: 5,
  },

  reducers: {
    //! добавить в массив фильтрацию по заголовку
    setId(state, action) {
      const { id } = action.payload;
      state.id = id || "";
    },
    setFilteredDate(state, action) {
      const { data } = action.payload;
      if (data) {
        state.filteredDate = data;
      }
    },
    setLengthMass(state) {
      state.lengthMass = state.lengthMass + 5;
    },
    setLen(state, action) {
      const { len } = action.payload;
      state.lengthMass = len;
    },
    setNewsData(state, action) {
      const { data } = action.payload;
      if (data) {
        state.newsData = data;
      }
    },
  },
});

export const { setId, setFilteredDate, setLengthMass, setLen, setNewsData } =
  idSlice.actions;

export default idSlice.reducer;
