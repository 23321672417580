"use client";

// export const metadata = {
//   title: "Next.js",
//   description: "Generated by Next.js",
// };
import styles from "./layout.module.scss";

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
      <div className={styles.RootLayout}>
        <div className={styles.RootLayoutInner}>
          {children}
        </div>
      </div>
  )
}
