import { useEffect } from "react";
import Footer from "../../components/Footer/page";
import Header from "../../components/Header/page";
import NewsPageMore from "../../page/NewsPageMore/page";
import "./../../styles/globals.css";

function NewsMore() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <NewsPageMore />
      <Footer />
    </div>
  );
}

export default NewsMore;
