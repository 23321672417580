import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Не забудьте импортировать стили
import "./style.css";

function CalendarComponent(props: any) {
  const [show, setShow] = useState(false);

  const refCalendar = useRef<HTMLElement | null>(null);
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (refCalendar.current && !refCalendar.current.contains(event.target)) {
        setShow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onChange = (date: any) => {
    console.log("date", date);
    props.setSelectedDateCalendar(date);
    console.log(date.toLocaleDateString().slice(0, 10)); // Вывод выбранной даты в консоль
  };
  //  <p>Выбранная дата: {date.toDateString()}</p>
  const handleActiveStartDateChange = ({ activeStartDate }: any) => {
    // Обновляем activeStartDate, если он изменяется через стрелки календаря
    props.setActiveStartDate(activeStartDate);
  };
  return (
    <div
      className="calendar2"
      ref={refCalendar as React.RefObject<HTMLDivElement>}
    >
      <div className={`calendarModal ${show ? "show" : ""}`}>
        <Calendar
          onChange={onChange}
          value={props.selectedDateCalendar}
          activeStartDate={props.activeStartDate}
          onActiveStartDateChange={handleActiveStartDateChange}
          locale="ru"
        />
        <p className="ok" onClick={() => setShow(false)}>
          ОК
        </p>
      </div>
      <div className="calendarText">
        <p onClick={() => setShow(!show)}>
          Календарь{" "}
          <img
            style={
              !show
                ? { transform: "rotate(180deg)" }
                : { transform: "rotate(0deg)" }
            }
            src="/img/arrow.svg"
            alt="img"
          />
        </p>
      </div>
    </div>
  );
}

export default CalendarComponent;
