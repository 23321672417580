import { Calendar } from "react-calendar";
import styles from "./page.module.scss";
import "react-calendar/dist/Calendar.css";
import { useEffect, useState } from "react";
import "./style.css";
import moment from "moment";
import { funFormatDateString } from "../../Functions";
import { apiGetHolidays } from "../../api/ApiRequest";

function formatDateToMonth(dateString: string) {
  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];
  const [year, month, day] = dateString.split("-");
  const monthIndex = parseInt(month, 10) - 1;
  return `${day} ${months[monthIndex]}`;
}

const TempleCalendarPage: React.FC = () => {
  const [holidays, setHolidays] = useState<string>("");
  const [dataTitle, setDataTitle] = useState<string>(getTodayDate());
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [closerDate1, setCloserDate1] = useState<any>("");
  const [date1, setDate1] = useState<any>("");
  const [closerDate2, setCloserDate2] = useState<any>("");
  const [date2, setDate2] = useState<any>("");

  function getTodayDate() {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Получаем день
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Получаем месяц, добавляем 1
    const year = today.getFullYear(); // Получаем год
    return `${day}.${month}.${year}`; // Форматируем строку
  }

  useEffect(() => {
    console.log("holidays", holidays);
  }, [holidays]);

  // const fetchHolidays = async (date: string) => {
  //   try {
  //     const response = await fetch(
  //       `https://azbyka.ru/days/widgets/presentations.json?date=${date}`
  //     );
  //     const data = await response.json();
  //     // setHolidays(data?.presentations || "");
  //     return data || "";
  //   } catch (error) {
  //     console.error("Error fetching church holidays:", error);
  //     return;
  //   }
  // };

  function formatDateString(dateString: string) {
    const [year, month, day] = dateString.split("-");
    return `${day}.${month}.${year}`;
  }

  useEffect(() => {
    apiGetHolidays("").then((data: any) => {
      console.log("data1", data);
      if (data) {
        setHolidays(data?.data?.presentations);
      }
    });

    const today = new Date();

    const newSelectedDate1 = funFormatDateString(
      moment(today).add(1, "days").toDate()
    );
    setDate1(formatDateToMonth(newSelectedDate1));
    apiGetHolidays(newSelectedDate1).then((data: any) => {
      if (data) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = data?.data?.presentations;
        // Извлекаем первый абзац
        const firstParagraph = tempDiv.querySelector(".paragraph-0");
        const textContent = firstParagraph
          ? Array.from(firstParagraph.querySelectorAll("li"))
              .map((item) => item.textContent)
              .join(", ")
          : "";
        setCloserDate1(textContent);
      }
    });

    const newSelectedDate2 = funFormatDateString(
      moment(today).add(2, "days").toDate()
    );
    setDate2(formatDateToMonth(newSelectedDate2));

    apiGetHolidays(newSelectedDate2 + "").then((data: any) => {
      if (data) {
        const tempDiv = document.createElement("div");
        tempDiv.innerHTML = data?.data?.presentations;
        // Извлекаем первый абзац
        const firstParagraph = tempDiv.querySelector(".paragraph-0");
        const textContent = firstParagraph
          ? Array.from(firstParagraph.querySelectorAll("li"))
              .map((item) => item.textContent)
              .join(", ")
          : "";
        setCloserDate2(textContent);
      }
    });
    // Создаем временный элемент для парсинга HTML
  }, []);

  // const handleDateChange = (value: any) => {
  //   if (value instanceof Date) {
  //     setSelectedDate(value);
  //     const formattedDate = value.toISOString().split("T")[0]; // Форматируем дату в YYYY-MM-DD
  //     const holiday = holidays.find(
  //       (holiday) => holiday.date === formattedDate
  //     );
  //     setHolidayInfo(holiday ? holiday.name : "Нет праздника на этот день");
  //   }
  // };

  const onChangeDate = (date: any) => {
    setSelectedDate(date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDataTitle(`${day}.${month}.${year}`);
    // fetchHolidays(formattedDate);
    apiGetHolidays(formattedDate).then((data: any) => {
      if (data) {
        setHolidays(data?.data?.presentations);
      }
    });
  };

  const funMinusStep = () => {
    const newDate = new Date(selectedDate);
    if (newDate > new Date("0001-01-01")) {
      newDate.setDate(newDate.getDate() - 1);
      setSelectedDate(newDate); // Обновляем состояние выбранной даты

      const year = newDate.getFullYear();
      const month = String(newDate.getMonth() + 1).padStart(2, "0");
      const day = String(newDate.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      setDataTitle(`${day}.${month}.${year}`);
      apiGetHolidays(formattedDate).then((data: any) => {
        if (data) {
          setHolidays(data?.data?.presentations);
        }
      });
      // Обновляем activeStartDate новыми значениями года и месяца
      const updatedActiveStartDate = new Date(activeStartDate);
      updatedActiveStartDate.setFullYear(year);
      updatedActiveStartDate.setMonth(newDate.getMonth());
      setActiveStartDate(updatedActiveStartDate);
    }
  };

  const funPlusStep = () => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + 1);
    setSelectedDate(newDate); // Обновляем состояние выбранной даты

    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDataTitle(`${day}.${month}.${year}`);
    apiGetHolidays(formattedDate).then((data: any) => {
      if (data) {
        setHolidays(data?.data?.presentations);
      }
    });
    // Обновляем activeStartDate новыми значениями года и месяца
    const updatedActiveStartDate = new Date(activeStartDate);
    updatedActiveStartDate.setFullYear(year);
    updatedActiveStartDate.setMonth(newDate.getMonth());
    setActiveStartDate(updatedActiveStartDate);
  };
  const [selectedDateCalendar, setSelectedDateCalendar] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(
    new Date(selectedDateCalendar)
  );
  const handleActiveStartDateChange = ({ activeStartDate }: any) => {
    // Обновляем activeStartDate, если он изменяется через стрелки календаря
    setActiveStartDate(activeStartDate);
  };
  return (
    <div className={styles.TempleCalendarPage}>
      <div className={styles.container}>
        <h2>Церковный календарь</h2>
        <div className={styles.box}>
          <div className={styles.calendar1}>
            <div className="calendarTamle">
              <Calendar
                locale="ru"
                onChange={onChangeDate}
                value={selectedDate}
                activeStartDate={activeStartDate}
                onActiveStartDateChange={handleActiveStartDateChange}
              />
            </div>

            {closerDate1 && closerDate2 && (
              <div className={styles.holidays}>
                <h3>Ближайшие праздники:</h3>
                <div className={styles.holidaysInner}>
                  <span>{date1}</span>
                  <p>{closerDate1}</p>
                </div>
                <div className={styles.holidaysInner}>
                  <span>{date2}</span>
                  <p>{closerDate2}</p>
                </div>
              </div>
            )}
          </div>

          <div className={styles.info}>
            <div className={styles.bottonNavigagte}>
              <div
                className={styles.bottonNavigagteInner}
                onClick={funMinusStep}
              >
                <img src="/img/arrowNawigateCalendar.svg" alt="img" />
                <span>Предыдущий день</span>
              </div>
              <div
                className={styles.bottonNavigagteInner}
                onClick={funPlusStep}
              >
                <span>Следующий день</span>
                <img
                  style={{ transform: "rotate(180deg)" }}
                  src="/img/arrowNawigateCalendar.svg"
                  alt="img"
                />
              </div>
            </div>
            <h3>
              Календарь дня: <p>{dataTitle}</p>
            </h3>
            <div
              className={styles.holidays}
              dangerouslySetInnerHTML={{
                __html:
                  holidays ||
                  "<p style='color: #3e280e;'>На эту дату нет праздников</p>",
              }}
            ></div>
          </div>
          {closerDate1 && closerDate2 && (
            <div className={styles.holidaysMin}>
              <h3>Ближайшие праздники:</h3>
              <div className={styles.holidaysInner}>
                <span>{date1}</span>
                <p>{closerDate1}</p>
              </div>
              <div className={styles.holidaysInner}>
                <span>{date2}</span>
                <p>{closerDate2}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TempleCalendarPage;
