import styles from "./Payment.module.scss";

function Payment(props: any) {
  return (
    <div className={styles.Payment}>
      <div className={styles.container}>
        <img
          onClick={() => props.setShowPayment(false)}
          className={styles.close}
          src="./img/x.svg"
          alt="img"
        />
        <div
          className={styles.mixplatForm}
          id="6d20a491-ea8d-4988-b0ea-0233786cb41c"
          data-type="mixplat-form"
          dangerouslySetInnerHTML={{
            __html: "<mixplat-form amount=500></mixplat-form>",
          }}
        />
      </div>
    </div>
  );
}

export default Payment;
