import { useEffect } from "react";
import Footer from "../../components/Footer/page";
import Header from "../../components/Header/page";
import SoulsModule from "../../module/SoulsModule/page";
import "./../../styles/globals.css";

function Souls() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <SoulsModule />
      <Footer />
    </div>
  );
}

export default Souls;
