import styles from "./page.module.scss";

function Column(props: any) {
  return (
    <div
      onClick={() => props.funSetSelectedDate(props.index)}
      key={props.index}
      className={
        props.index === props.selecedDate
          ? `${styles.activeColumn} ${styles.column}`
          : props.index === props.selecedDate
          ? `${styles.selectedColumn} ${styles.column}`
          : styles.column
      }
    >
      <div className={styles.day}>{props.day}</div>
      <div className={styles.date}>
        {/* {props.currentDate.clone().day(props.index).date()} */}
        {props.currentDate.format("DD")}
      </div>
    </div>
  );
}

export default Column;
