import { useEffect } from "react";
import Footer from "../../components/Footer/page";
import Header from "../../components/Header/page";
import RightsPage from "../../page/RightsPage/page";
import "./../../styles/globals.css";

function Rights() {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <Header />
      <RightsPage />
      <Footer />
    </div>
  );
}

export default Rights;
